import icon from '../../assets/refresh-icon.png';

const RefreshButton = (props) => {

  const { refresh, backgroundColor } = props;

  return (
    <button
      onClick={ refresh }
      className="refresh-button"
      aria-label={ backgroundColor !== 'white' ? "refresh the homepage content and background colour" : "refresh the homepage content" }
    >
      <img src={ icon } alt="A refresh icon with two arrows in a circle formation" />
    </button>
  )
};

export default RefreshButton;