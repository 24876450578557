import { motion } from 'framer-motion';
import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import AudioPlayer from './AudioPlayer';

const SoundFragmentModal = (props) => {

  const { soundFragments, setPlayingTrack, setSidebarIsActive, pathname, sidebarIsActive } = props;

  const activeFragment = useMemo(() => {
    const currentIndex = parseFloat(pathname.replace('/audio/', ''));
    if (!soundFragments || !soundFragments.length || typeof currentIndex === 'undefined') return null;
    const soundFragment = { ...soundFragments[ currentIndex ] };
    if (!soundFragment) return null;
    const newSoundFragment = {
      ...soundFragment,
      index: currentIndex + 1
    };
    return newSoundFragment;
  }, [ soundFragments, pathname ]);

  useEffect(() => {
    if (activeFragment?.index) {
      setPlayingTrack({});
    }
  }, [ activeFragment, setPlayingTrack, setSidebarIsActive ]);
  
  if (!activeFragment) return null;

  return (
    <motion.div
      initial={ { opacity: 0 } }
      animate={ { opacity: 1 } }
      exit={ { opacity: 0 } }
      className={ `sound-fragment-modal modal ${ sidebarIsActive === true ? ' sidebar-is-active' : '' }` }
      key={ activeFragment.index }
    >
        <div className="modal__inner">
          <header className="modal__header">
            <h2
              className="modal__header__title"
            >{ activeFragment?.name ? activeFragment.name : `Track ${ activeFragment.index }` }</h2>
            <Link
              to="/"
              className="modal__close-button"
              role='button'
              tabIndex={ 0 }
              aria-label='Close audio track modal'
            >×</Link>
          </header>
          <div className="sound-fragment-modal__scrolling-content modal__scrolling-content" tabIndex={ 1 }>
            <h3 tabIndex={ 0 }>Transcript</h3>
            <p tabIndex={1}>{ activeFragment.value.transcript }</p>
          </div>
          <div className="modal__footer sound-fragment-modal__footer" tabIndex={ 2 }>
            <AudioPlayer
              { ...props }
              url={ activeFragment.value.file }
              trackId={ `sound${ activeFragment.index }` }
              isModal={ true }
            />
            </div>
        </div>
    </motion.div>
  );
};

export default SoundFragmentModal;