import AudioPlayer from './AudioPlayer';

const MainAudioTrackPlayer = (props) => {

  const { content } = props;
  const { fullTrack } = content;

  if (fullTrack && fullTrack !== '') {
    return (
      <div className="main-audio-track-player">
        <AudioPlayer
          url={fullTrack}
          {...props}
          trackId={'main'}
        />
      </div>
    );
  } else {
    return null;
  }
};

export default MainAudioTrackPlayer;