import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { motion } from 'framer-motion';
import LazyloadImage from './LazyloadImage';
import { useState, useEffect } from 'react';

const Gallery = (props) => {

  const { content, windowWidth, windowHeight } = props;
  const { photoGallery } = content;
  const [galleryImages, setGalleryImages] = useState([]);

  useEffect(() => {
    if (photoGallery) {
      const shuffledImagesArray = photoGallery.sort(() => Math.random() - 0.5);
      setGalleryImages(shuffledImagesArray);
    }
  }, [photoGallery]);

  const settings = {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false
        }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ style: 'ease' }}
      className="gallery"
    >
      <Slider {...settings}>
        {
          galleryImages &&
          galleryImages.map(
            (item, index) => (
              <div className="gallery__slide">
                <div
                  className="gallery__slide__inner"
                  style={{
                    height: windowWidth < 768 ? windowHeight - 104 + 'px' : windowHeight - 52 + 'px'
                  }}
                >
                  <LazyloadImage {...props} image={item} key={index} />
                </div>
              </div>
            )
          )
        }
      </Slider>
    </motion.div>
  )
};

export default Gallery;