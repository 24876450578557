import { motion } from 'framer-motion';
import SideBarSection from './SidebarSection';
import SidebarAudioLinks from './SidebarAudioLinks';

const SideBar = (props) => {

  const { windowWidth, windowHeight, sidebarSections } = props;

  return (
    <motion.div
      style={{
        height: windowWidth < 768 ? windowHeight - 52 + 'px' : windowHeight + 'px'
      }}
      className="side-bar"
      initial={{ x: '-100%' }}
      animate={{ x: 0 }}
      exit={{ x: '-100%' }}
      transition={{ style: 'ease', duration: 0.4 }}
    >
      <div className="side-bar__inner">
        {
          sidebarSections &&
          sidebarSections[0] &&
          sidebarSections.map(
            (section, index) => (
              <SideBarSection {...props} section={section} key={index} />
            )
          )
        }
        <SidebarAudioLinks {...props} />
      </div>
    </motion.div>
  )
};

export default SideBar;