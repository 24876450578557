import { AnimatePresence } from 'framer-motion';
import { useRef } from 'react';
import SoundFragment from './SoundFragment';

const SoundFragments = (props) => {

  const { fragments, setFragments } = props;
  const container = useRef();

  return (
    <div
      ref={ container }
      className="sound-fragments"
    >
      <AnimatePresence>
        {
          fragments.map(
            (fragment, index) => (
              <SoundFragment
                {...props}
                fragment={fragment}
                fragments={fragments}
                setFragments={setFragments}
                key={fragment._id + index}
                index={index}
                container={container}
              />
            )
          )
        }
      </AnimatePresence>
    </div>
  )
};

export default SoundFragments;