import { useRef, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { cockpitUrl } from '../utils/cockpit';
import * as _ from 'underscore';
import { durationInSecondsGetHours, durationInSecondsGetMinutes, durationInSecondsGetSeconds } from '../utils/duration.js';

const AudioPlayer = (props) => {

  const { url, isLooping, trackId, playingTrack, setPlayingTrack, isModal } = props;
  const [ isPlaying, setIsPlaying ] = useState(false);
  const [ duration, setDuration ] = useState(0);
  const [ currentTime, setCurrentTime ] = useState(0);

  const player = useRef();

  useEffect(() => {
    if (playingTrack?.id !== trackId) {
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
    }
  }, [ playingTrack, trackId ]);

  const getCurrentTime = (e) => {
    const timeElapsed = e.playedSeconds;
    setCurrentTime(timeElapsed);
  }

  const getCurrentTimeThrottled = _.throttle(getCurrentTime, 120);

  const getDuration = (e) => {
    const durationInSeconds = e;
    setDuration(durationInSeconds);
  }

  const handleSeek = (e) => {
    player.current.seekTo(e.target.value);
  }

  const handlePlay = () => {
    setIsPlaying(true);
    setPlayingTrack({ id: trackId, duration: duration });
  }

  const handleSeekThrottled = _.throttle(handleSeek, 120);

  if (url) {
    return (
      <div className="audio-player__wrapper">
      <div className={`audio-player${url.indexOf('storage') === 0 ? ' audio-player--audio-element' : ''}`}>
        <div className="audio__wrapper">
          <ReactPlayer
            ref={ player }
            url={ url.indexOf('storage') === 0 ? `${ cockpitUrl }/${ url }` : url.indexOf('/storage') === 0 ? `${ cockpitUrl }${ url }` : url }
            className="audio-player__player"
            playsinline={true}
            playing={isPlaying}
            volume={1}
            muted={false}
            height={'100%'}
            width={'100%'}
            loop={isLooping}
            onCanPlay={(e) => {
            }}
            onPlay={handlePlay}
            onPause={() => { setIsPlaying(false) }}
              onProgress={(e) => {
              getCurrentTimeThrottled(e)
            }}
            onSeek={(e) => {
              getCurrentTimeThrottled(e)
            }}
            onDuration={(e) => {
              getDuration(e)
            }}
          />
          </div>
          {
            trackId === 'main' || isModal ?
              <div className={ `audio-player__controls player__controls${ isModal === true ? ' is-modal' : '' }` }
              
              >
                <div className="audio-player__controls__inner player__controls__inner">
                  <div className="audio-player__time__wrapper player__time__wrapper">
                    <p className="audio-player__time player__time audio-player__time--elapsed player__time--elapsed">
                      {durationInSecondsGetHours(currentTime)}:{durationInSecondsGetMinutes(currentTime)}:{durationInSecondsGetSeconds(currentTime)}
                    </p>
                    <div className="audio-player__input--time__wrapper player__input--time__wrapper">
                      <input
                        type="range"
                        className="audio-player__input--time player__input--time"
                        name="time"
                        tabIndex={ 1 }
                        onKeyDown={ (e) => {
                          if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                            e.preventDefault();
                            setIsPlaying(false);
                            setCurrentTime(e.key === 'ArrowLeft' ? currentTime - 1 : currentTime + 1);
                            handleSeekThrottled(e);
                          }
                        } }
                        max={ duration }
                        aria-controls={ isModal === true ? 'current audio track' : 'main audio track'}
                        value={currentTime}
                        onChange={(e) => {
                          setIsPlaying(false);
                          setCurrentTime(e.target.value);
                          handleSeekThrottled(e);
                        }}
                        onMouseDown={() => {
                          setIsPlaying(false);
                        }}
                        onMouseUp={() => {
                          setIsPlaying(true);
                        }}
                      />
                    </div>
                    <p className="audio-player__time player__time audio-player__time--total player__time--total">
                      {durationInSecondsGetHours(duration)}:{durationInSecondsGetMinutes(duration)}:{durationInSecondsGetSeconds(duration)}
                    </p>
                    <button
                      aria-label={ isPlaying ? 'pause main audio track' : 'play main audio track' }
                      className={ `audio-player__button--play-pause player__button--play-pause ${ isPlaying === false ? 'play' : 'pause' }` }
                      tabIndex={ 2 }
                      onClick={(e) => {
                        typeof e !== 'undefined' && e.preventDefault();
                        setIsPlaying(!isPlaying);
                      }}
                    />
                  </div>
                </div>
              </div>
              :
              <div className={`sound-fragment__audio-player__wrapper${ isModal === true ? ' is-modal' : ''}`}>
                <button
                  aria-label={ isPlaying ? 'pause audio track' : 'play audio track' }
                  className={`audio-player__button--play-pause player__button--play-pause ${isPlaying === false ? 'play' : 'pause'}`}
                  onClick={(e) => {
                    typeof e !== 'undefined' && e.preventDefault();
                    setIsPlaying(!isPlaying);
                  }}
                />
                <p className="audio-player__time player__time audio-player__time--elapsed player__time--elapsed">
                  {`${durationInSecondsGetHours(currentTime)}:${durationInSecondsGetMinutes(currentTime)}:${durationInSecondsGetSeconds(currentTime)} / ${durationInSecondsGetHours(duration)}:${durationInSecondsGetMinutes(duration)}:${durationInSecondsGetSeconds(duration)}`}
                </p>
              </div>
          }
        </div>
      </div>
    )
  } else {
    return null;
  }
}

export default AudioPlayer;