import { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchCockpitData } from './utils/cockpit';
import Content from './components/Content';

const App = () => {
  const [ windowDimensions, setWindowDimensions ] = useState({
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth
  });
  const [ colorPickerIsActive, setColorPickerIsActive ] = useState(false);
  const { windowWidth, windowHeight } = windowDimensions;
  const [ sidebarIsActive, setSidebarIsActive ] = useState(false);
  const [ userIsTouching, setUserIsTouching ] = useState(false);
  const [ isResizing, setIsResizing ] = useState(false);
  const [ sidebarSections, setSidebarSections ] = useState([]);
  const [ content, setContent ] = useState({});
  const [ playingTrack, setPlayingTrack ] = useState({});
  const [ colors ] = useState([ 'green', 'pink', 'yellow', 'orange', 'white' ]);
  const [ backgroundColor, setBackgroundColor ] = useState(colors[ Math.floor(Math.random() * colors.length - 1) ]);
  const [ fragments, setFragments ] = useState([]);
  const [ isHighContrastColorMode, setIsHighContrastColorMode ] = useState(false);
  const [ introductionModalIsActive, setIntroductionModalIsActive ] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const sidebarIsActiveCurrent = useRef(!sidebarIsActive);

  const wrapper = useRef();
  const main = useRef();
  
  const { soundFragments, imageFragments } = content;

  useEffect(() => {
    if (sidebarIsActive === true && windowWidth > 767 && sidebarIsActiveCurrent.current !== sidebarIsActive) {
      const newFragmentArray = [ ...fragments ];
      for (let fragment of newFragmentArray) {
        if (fragment.x / 100 * windowWidth < 480) {
          const newFragmentX = ((fragment.x / 100 * windowWidth) + 480) / windowWidth * 100;
          fragment.x = newFragmentX;
        }
      };
      setFragments(newFragmentArray);
    }
    sidebarIsActiveCurrent.current = sidebarIsActive;
  }, [ fragments, windowWidth, sidebarIsActive ]);

  useEffect(() => {
    if (pathname !== '/') {
      setIntroductionModalIsActive(false);
    }
  }, [ pathname ]);

  const refresh = () => {
    if (imageFragments && soundFragments) {
      const shuffledImagesArray = imageFragments.sort(() => Math.random() - 0.5);
      const shuffledSoundsArray = soundFragments.sort(() => Math.random() - 0.5);

      const fragmentsArray = [];
      const maxNumber = window.innerWidth > 767 ? 9 : 5;

      for (let i = 0; i < Math.min(shuffledSoundsArray.length, maxNumber); i++) {
        fragmentsArray.push({
          sound: shuffledSoundsArray[i],
          image: shuffledImagesArray[ i % shuffledSoundsArray.length ],
          x: Math.floor(Math.random() * (100 - 240 / window.innerWidth * 100)),
          y: Math.floor(Math.random() * (100 - 240 / window.innerHeight * 100)),
          index: i,
          zIndex: i,
          _id: `fragment${i}`
        });
      }
      if (playingTrack.id !== 'main') {
        setPlayingTrack({});
      }
      setFragments([]);

      setTimeout(() => {
        setFragments(fragmentsArray);
      }, 600);
    }
    if (backgroundColor !== 'white') {
      setBackgroundColor(backgroundColor === 'green' ? 'pink' : backgroundColor === 'pink' ? 'yellow' : backgroundColor === 'yellow' ? 'orange' : 'green');
    }
  }

  useEffect(() => {
    if (imageFragments && soundFragments) {
      const shuffledImagesArray = imageFragments.sort(() => Math.random() - 0.5);
      const shuffledSoundsArray = soundFragments.sort(() => Math.random() - 0.5);
      const fragmentsArray = [];
      const maxNumber = window.innerWidth > 767 ? 9 : 5;

      for (let i = 0; i < Math.min(soundFragments.length, maxNumber); i++) {
        fragmentsArray.push({
          sound: shuffledSoundsArray[i],
          image: shuffledImagesArray[i % shuffledSoundsArray.length],
          x: Math.floor(Math.random() * (100 - 240 / window.innerWidth * 100)),
          y: Math.floor(Math.random() * (100 - 240 / window.innerHeight * 100)),
          index: i,
          zIndex: i,
          _id: `fragment${i}`
        });
      }

      setFragments(fragmentsArray);
    }
  }, [soundFragments, imageFragments]);

  useEffect(() => {
    // fetch our cockpit collections and singletons using the function from utils/cockpit.js
    fetchCockpitData('sidebarSections', setSidebarSections, true);
    fetchCockpitData('content', setContent, false);
  }, []);

  useEffect(() => {
    let resizingTimeout;

    const handleWindowResize = () => {
      clearTimeout(resizingTimeout);
      setIsResizing(true);
      setWindowDimensions({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth
      });
      
      // set a timeout to set a resizing state
      // this helps us prevent weird CSS transitions while the window is being resized
      resizingTimeout = setTimeout(() => {
        setIsResizing(false);
      }, 200);
    }

    // get the dimensions of the window on initial load
    handleWindowResize();

    //add an event listener to update the window dimensions on every window resize
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return (
    <Content
      { ...{
        colors,
        userIsTouching,
        main,
        wrapper,
        windowWidth,
        windowHeight,
        windowDimensions,
        sidebarIsActive,
        setSidebarIsActive,
        pathname,
        setUserIsTouching,
        isResizing,
        setIsResizing,
        location,
        refresh,
        navigate,
        sidebarSections,
        content,
        playingTrack,
        setPlayingTrack,
        backgroundColor,
        setBackgroundColor,
        fragments,
        setFragments,
        soundFragments,
        isHighContrastColorMode,
        setIsHighContrastColorMode,
        colorPickerIsActive,
        setColorPickerIsActive,
        introductionModalIsActive,
        setIntroductionModalIsActive,
      } }
    />
  );
}

export default App;