import { motion } from 'framer-motion';
import parse from 'html-react-parser';

const IntroductionModal = (props) => {

  const { sidebarIsActive, setIntroductionModalIsActive, content } = props;

  return (
    <motion.div
      initial={ { opacity: 0 } }
      animate={ { opacity: 1 } }
      exit={ { opacity: 0 } }
      className={ `modal ${ sidebarIsActive === true ? ' sidebar-is-active' : '' }` }
    >
        <div className="modal__inner">
          <header className="modal__header">
            <h2
              className="modal__header__title"
            >Welcome</h2>
          <button
            onClick={ () => setIntroductionModalIsActive(false) }
              className="color-picker-modal__close-button color-picker__close-button"
              tabIndex={ 0 }
              aria-label='Close audio track modal'
            >×</button>
          </header>
          <div className="modal__scrolling-content" tabIndex={ 1 }>
          <div tabIndex={ 1 }>{ content?.intro ? parse(content.intro) : '' }</div>
          <button
            className='modal__continue-button'
            onClick={ () => setIntroductionModalIsActive(false) }
            aria-label='Close introduction modal'
          >Continue</button>
          </div>
        </div>
    </motion.div>
  );
};

export default IntroductionModal;