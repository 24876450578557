import { useState, useEffect } from 'react';
import { getImage } from '../utils/cockpit';

const LazyloadImage = (props) => {

  const { image, w, h, isBackgroundImage, desaturate, callback, className, backgroundPositionX, backgroundPositionY } = props;
  const [imageSrcLarge, setImageSrcLarge] = useState('');
  const [imageIsLoaded, setImageIsLoaded] = useState(false);

  useEffect(() => {

    let img;
    const onImageLoad = () => {
      setImageIsLoaded(true);
    }

    if (image.path) {
      const path = image.path;

      getImage(
        path,
        w ? w : 2048,
        h ? h : 2048,
        100,
        (imageUrl) => {
          const url = imageUrl;
          setImageSrcLarge(url);
          img = document.createElement('img');
          img.addEventListener('load', onImageLoad);
          img.src = url;
        },
        desaturate ? ['desaturate'] : false
      );
    }

    return () => {
      if (img) {
        img.removeEventListener('load', onImageLoad);
      }
    }
  }, [image.path, w, h, desaturate]);

  useEffect(() => {
    if (imageIsLoaded === true) {
      callback && callback();
    }
  }, [imageIsLoaded, callback]);

  if (image?.path) {
    return (
      <img
        className={`image--lazyload${imageIsLoaded === true ? ' loaded' : ''}${className ? ` ` + className : ''}${isBackgroundImage === true ? ' background' : ''}`}
        src={imageSrcLarge}
        alt={image.title}
        style={{
          objectPosition: `${backgroundPositionX ? backgroundPositionX : 'center'} ${backgroundPositionY ? backgroundPositionY : 'center'}`
        }}
      />
    );
  } else {
    return null
  }
}

export default LazyloadImage;
