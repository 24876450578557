import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const Header = (props) => {

  const { sidebarIsActive, setSidebarIsActive, windowWidth, content } = props;
  const [fontWidth, setFontWidth] = useState(4);
  const [scaleX, setScaleX] = useState(1);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (windowWidth < 768) {
      setFontWidth(4);
      setScaleX(windowWidth / 480);
    } else {
      setFontWidth(480 / 120);
      setScaleX(1);
    }
  }, [windowWidth]);

  return (
    <header className="header">
      <AnimatePresence>
      <motion.h1
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.6, duration: 0.2 }}  
        className="header__title"
        aria-label='Stadium For The Future'
        role='title'
        style={{
          width: content?.fullTrack ? null : '100%'
        }}
      >
        <button
          aria-haspopup="true"
          className="button--plain header__title__button"
          onClick={() => {
            if (location.pathname === '/gallery') {
              navigate('/');
            } else {
              setSidebarIsActive(true);
            }
          }}
          style={{
            fontVariationSettings: `'wdth' ${fontWidth}`,
            transform: `scaleX(${scaleX})`,
            transformOrigin: 'left',
            width: windowWidth < 767 ? windowWidth / scaleX + 'px' : 480 / scaleX + 'px'
          }}
        >
          Stadium For The Future
        </button>
        </motion.h1>
      </AnimatePresence>
      <button
        className={`button--plain header__title__button--close${sidebarIsActive === true ? ' active' : ''}`}
        onClick={() => {
          setSidebarIsActive(false);
        }}
        aria-label="close sidebar"
        style={{
          fontVariationSettings: `'wdth' ${fontWidth}`,
            transform: `scaleX(${scaleX})`,
            transformOrigin: 'left',
            width: sidebarIsActive === true ? windowWidth < 767 ? windowWidth / scaleX + 'px' : 480 / scaleX + 'px' : null
        }}
      >
        Stadium For The Future
      </button>
      <div className={`header__sidebar-border${sidebarIsActive === true ? ' active' : ''}`} />
    </header>
  )
}

export default Header;