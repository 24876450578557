import { Link } from 'react-router-dom';

const SidebarAudioLinks = (props) => {

  const { soundFragments } = props;

  return (
    <div className="sidebar__section">
      <h2
        className="sidebar__section__title"
        aria-label='Audio tracks'
      >Audio tracks</h2>
      <p>Click the links below to listen to the audio tracks with transcripts.</p>
      <ul className='sidebar__section__link-list'>
        {
          soundFragments &&
          soundFragments.map(
            (fragment, index) => (
              <li
                className='sidebar__section__link-list__item'
                key={ index }
              >
                <Link
                  to={ `/audio/${index}` }
                  className="sidebar__section__link"
                  aria-label={ fragment.name ?? `Track ${index}` }
                >
                  { fragment.name ?? `Track ${index}` }
                </Link>
              </li>
            ))
        }
      </ul>
    </div>
  );
};

export default SidebarAudioLinks;