const ColorPickerButton = ({ setColorPickerIsActive, colorPickerIsActive }) => {

  return (
    <button className="color-picker-button"
      onClick={ () => setColorPickerIsActive(!colorPickerIsActive) }
    >
      Change colours
    </button>
  );
};

export default ColorPickerButton;