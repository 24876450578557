import { useRef } from 'react';
import { Routes, Route, useLocation, Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Header from './Header';
import SideBar from './SideBar';
import MainAudioTrackPlayer from './MainAudioTrackPlayer';
import SoundFragments from './SoundFragments';
import RefreshButton from './RefreshButton';
import Gallery from './Gallery';
import SoundFragmentModal from './SoundFragmentModal';
import ColorPickerButton from './ColorPickerButton';
import ColorPicker from './ColorPicker';
import IntroductionModal from './IntroductionModal';

const Content = (props) => {

  const { windowHeight, sidebarIsActive, backgroundColor, colorPickerIsActive, introductionModalIsActive } = props;
  const landingPage = useRef();
  const location = useLocation();

  return (
    <div
      className={`app ${backgroundColor}`}
      style={{
        height: windowHeight + 'px'
      }}
    >
      <Header {...props} />
      <AnimatePresence exitBeforeEnter>        
        <Routes key={ location.pathname === '/gallery' ? 'gallery' : 'homepage' }>
          <Route
            path="/gallery"
            key="gallery page"
            element={
              <Gallery {...props} />
            }
          />
          <Route
            path="*"
            key="landing page"
            element={
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ style: 'ease' }}
                ref={landingPage}
                className="landing-page"
                style={{
                  height: windowHeight + 'px'
                }}
              >
                <SoundFragments {...props} />
                <Link
                  className="gallery-link"
                  to="/gallery"
                >Gallery</Link>
              </motion.div>
            }
          />
        </Routes>
      </AnimatePresence>
      <AnimatePresence>
        {
          sidebarIsActive === true &&
          <SideBar {...props} />
        }
      </AnimatePresence>
      <MainAudioTrackPlayer { ...props } />
      <div className='color-buttons'>
        <ColorPickerButton { ...props } />
        <RefreshButton { ...props } />
      </div>
      <AnimatePresence exitBeforeEnter>
        {
          location.pathname.indexOf('/audio/') === 0 &&
          <SoundFragmentModal
            { ...props }
            pathname={ location.pathname }
          />
        }
        {
          introductionModalIsActive === true &&
          <IntroductionModal
            { ...props }
          />
        }
      </AnimatePresence>
      <AnimatePresence>
        {
          colorPickerIsActive === true &&
          <ColorPicker { ...props } />
        }
      </AnimatePresence>
    </div>
  );
};

export default Content;