import { useRef, useState, useEffect, useMemo } from 'react';
import { motion } from 'framer-motion';
import AudioPlayer from './AudioPlayer';
import { getImage } from '../utils/cockpit';

const SoundFragment = (props) => {

  const { windowWidth, windowHeight, fragment, fragments, setFragments, playingTrack, setPlayingTrack, sidebarIsActive } = props;
  const { image, sound, index } = fragment;
  const [ fragmentHeight, setFragmentHeight ] = useState(-1);
  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ fragmentWidth, setFragmentWidth ] = useState(-1);
  const [ isHovered, setIsHovered ] = useState(false);
  const [ mouseIsDown, setMouseIsDown ] = useState(false);
  const modal = useRef();
  const canvas = useRef();

  const dragConstraints = useMemo(() => {
    const constraints = { top: 0, left: 0, bottom: 0, right: 0 };

    if (sidebarIsActive === true && windowWidth > 767) {
      constraints.top = 0 - (fragment.y / 100 * windowHeight) - 1;
      constraints.left = 480 - (fragment.x / 100 * windowWidth) - 1;
      constraints.bottom = ((100 - fragment.y) / 100 * windowHeight) - (fragmentHeight + 51);
      constraints.right = ((100 - fragment.x) / 100 * windowWidth) - fragmentWidth + 1;
    } else {
      constraints.top = 0 - (fragment.y / 100 * windowHeight) - 1;
      constraints.left = 0 - (fragment.x / 100 * windowWidth) - 1;
      constraints.bottom = ((100 - fragment.y) / 100 * windowHeight) - (fragmentHeight + 51);
      constraints.right = ((100 - fragment.x) / 100 * windowWidth) - fragmentWidth + 1;
    }

    return constraints;

  }, [ windowWidth, windowHeight, sidebarIsActive, fragment.x, fragment.y, fragmentHeight, fragmentWidth ]);

  useEffect(() => {
    const img = document.createElement('img');
    img.crossOrigin = 'anonymous';

    const handleImgLoad = () => {
      if (canvas.current) {
        const ctx = canvas.current.getContext('2d');
        canvas.current.width = img.naturalWidth;
        canvas.current.height = img.naturalHeight;
        ctx.drawImage(img, 0, 0);
        setFragmentWidth(modal.current.offsetWidth);
        setFragmentHeight(modal.current.offsetHeight);
        setIsLoaded(true);
      }
    }

    const handleGetImageUrl = (url) => {
      img.addEventListener('load', handleImgLoad);
      img.src = url;
    }
      
    if (image?.path) {
      getImage(image.path, 640, 640, 30, handleGetImageUrl);
    }

    return () => {
      img.removeEventListener('load', handleImgLoad);
    }
  }, [ image ]);

  const handleMouseDown = (e, item) => {
    const fragmentsArray = [ ...fragments ];

    for (let i = 0; i < fragmentsArray.length; i++) {
      if (fragmentsArray[i]._id === item._id) {
        fragmentsArray[i].zIndex = fragmentsArray.length;
      } else {
        fragmentsArray[i].zIndex = Math.max(1, fragmentsArray[i].zIndex - 1);
      }
    }

    setFragments(fragmentsArray);
  }

  const [ sidebarUpdateCount, setSidebarUpdateCount ] = useState(0);
  const sidebarUpdateCountCurrent = useRef(0);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    let timeout = null;
    sidebarUpdateCountCurrent.current = sidebarUpdateCountCurrent + 1;
    setSidebarUpdateCount(sidebarUpdateCountCurrent.current);

    if (sidebarIsActive === true && windowWidth > 767) {
      setIsUpdating(true);
      timeout = setTimeout(() => {
        setIsUpdating(false);
      }, 400);
    }

    return () => {
      clearTimeout(timeout);
    }
  }, [ sidebarIsActive, windowWidth ]);

  return (
    <motion.div
      key={ fragment._id + '-' + sidebarUpdateCount }
      className={ `sound-fragment${ isHovered === true || playingTrack.id === `fragment${ index }` ? ' active' : '' }` }
      drag={ isUpdating === false ? true : false }
      tabIndex={ props.location.pathname.indexOf('/audio/') === 0 ? -1 : index + 1 }
      initial={ { opacity: 0 } }
      aria-hidden={ props.location.pathname.indexOf('/audio/') === 0 ? true : false }
      aria-grabbed={ mouseIsDown === true ? true : false }
      aria-label={ sound?.value?.name ? 'audio element: ' + sound.value.name : 'audio element' }
      onFocus={ (e) => {
        setIsHovered(true);
      } }
      onKeyDown={ (e) => {
        if (e.key === 'Enter') {
          if (playingTrack.id === `fragment${ index }`) {
            setPlayingTrack({});
          } else {
            setPlayingTrack({ id: `fragment${ index }` });
          } 
        }
      } }
      animate={
      isUpdating === true ?
        {
          opacity: 1,
          x: 0
        }
        :
        {
          opacity: isLoaded === true ? 1 : 0
        }
      }
      transition={ {
        style: 'ease',
        duration: 0.4,
      }}
      exit={ { opacity: 0 } }
      dragConstraints={ dragConstraints }
      dragElastic={false}
      dragMomentum={false}
      style={{
        zIndex: fragment.zIndex + 1,
        // left: windowWidth > 767 && sidebarIsActive === true && (fragment.x / 100 * windowWidth < 480) ? (fragment.x) / 100 * (windowWidth - 480) + 480 + 'px' :
        transition: 'left 0.4s ease',
        left: fragment.x / 100 * windowWidth + 'px',
        top: fragment.y / 100 * windowHeight + 'px'
      }}
      onMouseDown={(e) => {
        setIsHovered(true);
        handleMouseDown(e, fragment);
        setMouseIsDown(true);
      }}
      onMouseUp={(e) => {
        setMouseIsDown(false);
      }}
      onTouchStart={(e) => {
        setIsHovered(true);
        handleMouseDown(e, fragment);
      }}
      onMouseEnter={(e) => {
        setIsHovered(true);
        setMouseIsDown(false);
      }}
      onMouseLeave={(e) => {
        setIsHovered(false);
        setMouseIsDown(false);
      }}
      onMouseMove={(e) => {
        setIsHovered(true);
      }}
      onBlur={() => {
        setIsHovered(false);
        setMouseIsDown(false);
      }}
      ref={modal}
    >
      <canvas
        className={`sound-fragment__canvas${mouseIsDown === true ? ' cursor--grabbing' : ' cursor--grab'}${isLoaded === true ? ' loaded' : ''}`}
        ref={canvas}
      />
      {
        sound?.value?.file &&
        <div
          className="sound-fragment__handle"
          aria-hidden={ isHovered === false ? true : false }
          style={{
            opacity: isHovered === true || playingTrack.id === `fragment${index}` ? 1 : 0,
            pointerEvents: isHovered === true || playingTrack.id === `fragment${index}` ? 'auto' : 'none'
          }}
        >
          <AudioPlayer {...props} url={sound.value.file} trackId={`fragment${index}`} />
        </div>
      }
    </motion.div>
  )
}

export default SoundFragment;