import parse from 'html-react-parser';

const SidebarSection = (props) => {

  const { section } = props;
  const { heading, content } = section;

  return (
    <div className="sidebar__section">
      <h2
        className="sidebar__section__title"
        aria-label={ heading }
      >{ heading }</h2>
      {
        content &&
        <div
          className="sidebar__section__content"
          role='contentinfo'
        >
          { parse(content) }
        </div>
      }
    </div>
  )
};

export default SidebarSection;