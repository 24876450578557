import { motion } from 'framer-motion';

const ColorPicker = (props) => {
  
  const { colors, setBackgroundColor, setColorPickerIsActive, sidebarIsActive } = props;

  return (
    <motion.div
      initial={ { opacity: 0 } }
      animate={ { opacity: 1 } }
      exit={ { opacity: 0 } }
      className={ `color-picker-modal ${ sidebarIsActive === true ? ' sidebar-is-active' : '' }` }
    >
        <div className="color-picker-modal__inner">
          <header className="color-picker-modal__header">
          <h2
            tabIndex={ 0 }
            className="color-picker-modal__header__title"
          >Colour Picker</h2>
          <button
            tabIndex={ 1 }
            onClick={ () => setColorPickerIsActive(false) }
              className="color-picker-modal__close-button color-picker__close-button"
              aria-label='Close audio track modal'
            >×</button>
          </header>
          <p className='color-picker__instructions'>Select a colour below to change the background colour of the page</p>
        <div className="color-picker__options">
          {
            colors.map(
              (color, index) => (
                <button
                  tabIndex={ index + 2 }
                  key={ index }
                  className={ `color-picker__option ${ color }` }
                  aria-label={ `Change background color to ${ color }` }
                  onClick={ () => setBackgroundColor(color) }
              />
          ))
        }
        </div>
      </div>
    </motion.div>
  );
};

export default ColorPicker;